<template lang="pug">
v-container
  v-row.mt-5
    v-col(cols="12")
      h5.normal_title_h5(:class="{ 'text-center': textCenter }") 球隊排行
      v-divider.d-block(v-if="divider")
  v-row.table_row
    v-col#sticky-nav.table_col.table(cols="12")
      table
        thead
          tr.bottom_head
            sticky-column(
              style="width: 50px; min-width: 50px",
              :title="'排名'",
              :offset="0"
            )
            sticky-column.bdr2.text-center(
              style="width: 120px; min-width: 120px",
              :title="'球隊'",
              :offset="50"
            )
            th.bdr(v-for="header in teamLeadersHeaders", :key="header") {{ header }}
        tbody
          tr.hover(
            v-for="(team, index) in teamLeaders",
            :key="index",
            style="cursor: pointer",
            @click="teamClick(team)"
          )
            sticky-column.bdl(
              style="width: 50px; min-width: 50px",
              :offset="0"
            )
              .ranking.text-center(
                :class="[index < 3 ? 'text_secondary' : 'lightGray']"
              ) {{ getRankText(index + 1) }}
            sticky-column.bdr2(
              style="width: 120px; min-width: 120px",
              :offset="50"
            )
              .team_container.d-flex.justify-center
                span.ml-1 {{ team.name }}
            td.bdr {{ team.gp }}
            td.bdr {{ team.wins }}
            td.bdr {{ team.losses }}
            td.bdr {{ team.win_pct + "%" }}
            td.bdr {{ team.games_behind }}
            td {{ getStreakText(team.winning_streak) }}
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";

export default {
  name: "TeamLeaderTable",
  components: {
    StickyColumn,
  },
  props: {
    teamLeaders: {
      type: Array,
      required: true,
    },
    textCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    divider: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      teamLeadersHeaders: ["場次", "勝", "敗", "勝率", "勝差", "連勝/連敗"],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    async initData() {},
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
      this.seasonId = node.season_id;
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getStreakText(count) {
      if (count > 1) {
        return count + "連勝";
      } else if (count < -1) {
        return 0 - count + "連敗";
      } else {
        return "-";
      }
    },
    teamClick(team) {
      this.$emit("teamClick", team);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
</style>
