<template lang="pug">
.league
  season-segment-selector(
    :leagueId="leagueId",
    @leaf-node-clicked="leafNodeClicked"
  )
  v-container
    v-row.mt-16
      v-col(cols="12")
        h5.normal_title_h5 賽事列表
        v-divider.d-block
    v-row.table_row
      v-col.table_col.table.position-relative(cols="12")
        table.text-center
          thead
            th.bottom_head(
              v-for="(title, index) in gameEventHeadTitleList",
              :key="index",
              :class="{ bdr: index != gameEventHeadTitleList.length - 1 }"
            ) {{ title }}
          tbody
            tr.hover(
              v-for="(game, index) in gamePage",
              :key="index",
              style="cursor: pointer",
              @click="gameClick(game)"
            )
              td.bdl.bdr {{ game.stage }}
              td.bdr {{ game.name }}
              td.bdr {{ game.date }}
              td.bdr
                .team_container.d-flex.align-center.justify-center
                  span {{ game.away_team_name + " " }}
                  span.ml-1(
                    :class="[game.home_team_pts < game.away_team_pts ? 'text_secondary' : 'text_light_gray']"
                  ) {{ game.away_team_pts }}
                  span.mx-2 @
                  span {{ game.home_team_name + " " }}
                  span.ml-1(
                    :class="[game.home_team_pts > game.away_team_pts ? 'text_secondary' : 'text_light_gray']"
                  ) {{ game.home_team_pts }}
    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
  team-leader-table(:teamLeaders="teamLeaders", @teamClick="teamClick")
  v-container
    v-row.mt-16
      v-col(cols="12")
        h5.normal_title_h5 球員排行
        v-divider.d-block
    v-row.mt-md-5
      players-ranking-card-slider(
        :playerLeaders="playerOffensiveLeaders",
        :leaderTitles="rankingTitles[0]",
        @playerClick="playerClick"
      )
      players-ranking-card-slider(
        :playerLeaders="playerDefensiveLeaders",
        :leaderTitles="rankingTitles[1]",
        @playerClick="playerClick"
      )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import SeasonSegmentSelector from "@/components/league/SeasonSegmentSelector";
import TeamLeaderTable from "@/components/league/TeamLeaderTable";
import PlayersRankingCardSlider from "@/components/league/PlayersRankingCardSlider";
import { getLeagueOverview } from "@/api/league";

export default {
  name: "LeagueLeagueOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    PlayersRankingCardSlider,
    StickyColumn,
    SeasonSegmentSelector,
    TeamLeaderTable,
  },
  data() {
    return {
      leagueId: Number(this.$route.params.leagueId),
      seasonId: 0,
      seasonSegmentId: 0,
      games: [],
      gameEventHeadTitleList: ["賽別", "場次", "日期", "客隊＠主隊"],
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
      teamLeaders: [],
      teamLeadersHeaders: ["場次", "勝", "敗", "勝率", "勝差", "連勝/連敗"],
      rankingTitles: [
        {
          title: "進攻排名",
          titleEng: "Offensive Leaders",
        },
        {
          title: "防守排名",
          titleEng: "Defensive Leaders",
        },
      ],
      playerOffensiveLeaders: [],
      playerDefensiveLeaders: [],

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    gamePage() {
      // Return a portion of this.games by this.currentPage, this.pageSize, this.totalPages
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.games.slice(start, end);
    },
  },
  watch: {
    seasonSegmentId() {
      this.teamLeaders = [];
      this.games = [];
      this.currentPage = 1;
      this.totalPages = 1;
      this.playerOffensiveLeaders = [];
      this.playerDefensiveLeaders = [];
      this.getLeagueOverview();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {},
    async getLeagueOverview() {
      const params = { season_segment_id: this.seasonSegmentId };
      const response = await getLeagueOverview(params);
      this.games = response.data.games;
      this.totalPages = Math.ceil(this.games.length / this.pageSize);
      this.teamLeaders = response.data.team_leaders;
      this.playerOffensiveLeaders = response.data.player_leaders.offensive;
      this.playerDefensiveLeaders = response.data.player_leaders.defensive;
    },
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
      this.seasonId = node.season_id;
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getStreakText(count) {
      if (count > 1) {
        return count + "連勝";
      } else if (count < -1) {
        return 0 - count + "連敗";
      } else {
        return "-";
      }
    },
    gameClick(game) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          leagueId: this.leagueId,
          seasonSegmentId: this.seasonSegmentId,
          id: game.game_id,
        },
      });
    },
    teamClick(team) {
      this.$router.push({
        name: "LeagueTeam",
        params: {
          leagueId: this.leagueId,
          teamId: team.team_id,
          seasonSegmentId: this.seasonSegmentId,
        },
      });
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          leagueId: this.leagueId,
          seasonSegmentId: this.seasonSegmentId,
          playerId: player.player_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
</style>
